import "@fontsource/fira-code/400.css";
import "@fontsource/fira-code/600.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import React from "react";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { GatsbyBrowser } from "gatsby";
import { PrivacyPolicyCheck } from "@components/PrivacyPolicyCheck";

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps,
}) => !routerProps.location.state?.disableScrollUpdate;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
    <>
      <PrivacyPolicyCheck />
      {element}
    </>
  );
};

const client = new ApolloClient({
  uri: process.env.GATSBY_API_URL + "/api/graphql",
  headers: {
    "apollographql-client-name": "Apollo Odyssey",
    "apollographql-client-version": "1.0",
  },
  credentials: "include",
  cache: new InMemoryCache(),
});

let isLoggedIn: boolean | null = null;
const eventDomains = ["www.apollographql.com"];
let lastHref: string | null = null;

async function emitPageView(location: Location) {
  if (!eventDomains.includes(location.hostname)) {
    return;
  }
  if (lastHref === location.href) {
    return;
  }
  try {
    if (isLoggedIn === null) {
      const { data } = await client.query<{
        me: {
          __typename: "User";
          id: string;
        } | null;
      }>({
        query: gql`
          query Odyssey_WhoAmI {
            me {
              __typename
              id
            }
          }
        `,
      });
      isLoggedIn = data.me?.__typename === "User";
    }
    if (!isLoggedIn) {
      return;
    }
    await client.mutate({
      mutation: gql`
        mutation PublishEDUEvent($type: String!, $data: String!) {
          publishEDUEvent(type: $type, data: $data)
        }
      `,
      variables: {
        type: "page_view",
        data: JSON.stringify({
          url: location.href,
          referrer: document.referrer,
        }),
      },
    });
    lastHref = location.href;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = async ({
  location,
}) => {
  await emitPageView(location);
};
