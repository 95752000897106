import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Callout } from "../components/Callout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Callout mdxType="Callout">
  Want a faster way to get started with GraphQL using existing REST APIs?
  [Apollo Connectors make it easier than ever!](/connectors-intro-rest) No
  servers, no complex resolvers; everything happens in the schema.
    </Callout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      