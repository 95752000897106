import chroma from "chroma-js";
import confetti from "canvas-confetti";
import {
  Account,
  CreateCertificationMutation,
  GetUserDocument,
  GetUserQuery,
  OdysseyTask,
  useGetUserQuery,
} from "@graphql";
import { MutationUpdaterFn } from "@apollo/client";
import { RefObject, useEffect, useRef } from "react";
import { addDays, differenceInDays, format } from "date-fns";
import { colors } from "@apollo/space-kit/colors";
import { graphql, useStaticQuery, withPrefix } from "gatsby";
import { shuffle } from "shuffle-seed";
import { useColorModeValue } from "@chakra-ui/react";

export const EXAM_RETAKE_WAITING_PERIOD = 3; // number of days between attempts

export const IS_PROD = process.env.CONTEXT === "production";

export const formatDate = (date: Date) => format(new Date(date), "PP");

export const getExamRetakeDate = (startedDate: Date) =>
  addDays(startedDate, EXAM_RETAKE_WAITING_PERIOD);

export const CONTAINER_PADDING_X = [6, 8, 10, 12];

export const CONFETTI_CERTIFIED_COLORS = chroma
  .scale(["#f4d03f", "#f59140"])
  .colors(5);

export const CONFETTI_COLORS = [
  colors.teal.base,
  colors.orange.light,
  colors.midnight.lighter,
  colors.blilet.lighter,
  colors.silver.dark,
  colors.blilet.light,
  colors.midnight.lightest,
];

export const CONFETTI_CONFIG = {
  startVelocity: 45,
  particleCount: 70,
  colors: CONFETTI_COLORS,
};

export function useConfetti<TRef extends HTMLElement>(
  options?: confetti.Options & {
    position?: "center" | "bottom-left";
  }
) {
  const ref = useRef<TRef>(null);
  return [
    ref,
    () => {
      if (ref.current) {
        const position = options?.position ?? "center";
        const { top, height, left, width } =
          ref.current.getBoundingClientRect();
        let y: number;
        let x: number;
        if (position === "center") {
          y = (top + height / 2) / innerHeight;
          x = (left + width / 2) / innerWidth;
        } else if (position === "bottom-left") {
          y = (top + height) / innerHeight;
          x = left / innerWidth;
        } else {
          throw new Error(`Invalid position: ${position}`);
        }
        confetti({
          ...CONFETTI_CONFIG,
          disableForReducedMotion: true,
          origin: { x, y },
          angle: 45,
          spread: 120,
          ...options,
        });
      }
    },
  ] as const;
}

export function useCertificationConfetti(
  ref: RefObject<HTMLCanvasElement>,
  options: confetti.Options = {}
) {
  useEffect(() => {
    if (ref.current) {
      const fireConfetti = confetti.create(ref.current, { resize: true });
      fireConfetti({
        disableForReducedMotion: true,
        colors: chroma.scale(["#f4d03f", "#f59140"]).colors(5),
        particleCount: 100,
        spread: 90,
        angle: 270,
        origin: {
          y: -1,
        },
        ...options,
      });
    }
  }, [ref, options]);
}

export const getConnectorsStatus = (user: OdysseyUser) => {
  if (!user) return null;

  const connectorsCourse = user?.courses?.find(
    (c) => c.id === "connectors-intro-rest"
  );

  const { enrolledAt, completedAt } = connectorsCourse ?? {};

  return {
    hasCompletedConnectors: !!completedAt,
    hasEnrolledInConnectors: !!enrolledAt,
    enrolledAt,
  };
};

export const updateCertifications: MutationUpdaterFn = (cache, { data }) => {
  const cacheData = cache.readQuery<GetUserQuery>({
    query: GetUserDocument,
  });

  if (cacheData) {
    const { me } = cacheData;
    if (me?.__typename === "User" && Array.isArray(me.certifications)) {
      cache.writeQuery<GetUserQuery>({
        query: GetUserDocument,
        data: {
          me: {
            ...me,
            certifications: [...me.certifications, data?.user.certification],
          },
        },
      });
    }
  }
};

type SendCertificationReportOptions = {
  title: string | null;
  data: CreateCertificationMutation;
} & Pick<SendReportOptions, "user">;

export function useCertificationReport(): (
  options: SendCertificationReportOptions
) => void {
  const siteUrl = useSiteUrl();
  return ({ user, title, data }) => {
    const path = withPrefix(`/certifications/${data.user?.certification?.id}`);
    sendReport({
      type: "certification",
      user,
      name: title || "",
      url: siteUrl + path,
    });
  };
}

type SendReportOptions = {
  type: string;
  name: string;
  url: string;
  user: {
    fullName: string;
    email?: string | null;
    githubUsername?: string | null;
  };
};

export function sendReport({ type, name, user, url }: SendReportOptions) {
  if (IS_PROD) {
    const params = new URLSearchParams({
      type,
      name,
      url,
      user_name: user.fullName,
    });

    if (user.email) {
      params.append("user_email", user.email);
    }

    if (user.githubUsername) {
      params.append("user_github", user.githubUsername);
    }

    fetch(`${process.env.GATSBY_ZAPIER_URL}?${params}`);
  }
}

export function useButtonColor(color: string) {
  const isGray = color === "gray";
  return useColorModeValue(
    `${color}.${isGray ? 100 : 500}`,
    `${isGray ? "whiteAlpha" : color}.200`
  );
}

const demoOrgs = ["acephei-corp"];

export const isOrgWithEnterpriseAccess = (membership: {
  account: Pick<Account, "id">;
}) => !demoOrgs.includes(membership.account.id);

export const isInApolloOrg = (
  userMemberships: Array<{ account: Pick<Account, "id"> }>
) =>
  userMemberships.some(({ account }) =>
    ["gh.apollographql", "apollo-private", "apollo"].includes(account.id)
  );

export type HasCourseAccessOptions = {
  user: ReturnType<typeof useUser>["user"];
  memberships: readonly string[] | null;
  tiers: readonly string[] | null;
  isPartnerCourse?: boolean;
  courseStatus?: string | null;
};

export function hasCourseAccess({
  user,
  memberships,
  tiers,
  isPartnerCourse,
}: HasCourseAccessOptions) {
  if (!isPartnerCourse && !memberships && !tiers) {
    return true;
  }

  if (!user) {
    return false;
  }

  // if the course is a partner course and the user passes the partner check,
  // let them in
  if (isPartnerCourse && isPartner(user)) {
    return true;
  }

  // then, check if the user has access via their memberships
  if (memberships) {
    const userMemberships = user.memberships.map(({ account }) => account.id);
    const hasMembershipAccess = memberships.some((orgId) =>
      userMemberships.includes(orgId)
    );

    if (hasMembershipAccess) {
      return true;
    }
  }

  // if no membership access, then check for access via tiers
  if (tiers) {
    const userTiers = user.memberships
      .filter(isOrgWithEnterpriseAccess)
      .map(({ account }) => account.currentPlan.tier);
    const hasTierAccess = tiers.some((tier) =>
      userTiers.some((userTier) => userTier === tier)
    );

    if (hasTierAccess) {
      return true;
    }
  }

  return false;
}

export const isPartner = (user: OdysseyUser) => {
  const partnerAccounts = ["xolvio", "apollo"];
  return user.memberships.some(
    (membership) =>
      membership.account.currentPlan.id === "sub-engine-ent-partner" ||
      partnerAccounts.includes(membership.account.id)
  );
};

type ExamResponse = {
  id: string;
  questionId: string;
};

type GetRandomVariantOptions = {
  variants: readonly Queries.QuestionVariant[];
  attemptId: string;
  prevQuestions: ExamResponse[];
};

export function getRandomVariant({
  variants,
  attemptId,
  prevQuestions,
}: GetRandomVariantOptions): Queries.QuestionVariant {
  if (!variants.length) {
    throw new Error("An exam question is missing variants.");
  }

  // allow user to specify which variant via url
  // if in local dev or deploy preview
  if (!IS_PROD) {
    const urlParams = new URLSearchParams(window.location.search);
    const paramVariant = Number(urlParams.get("variant"));

    if (paramVariant && paramVariant > 0 && paramVariant <= variants.length) {
      return variants[paramVariant - 1];
    }
  }

  // filter out question variants used in previous exam attempts
  const availableVariants = variants.filter((variant) => {
    return !prevQuestions?.find((prevQ) => prevQ.questionId === variant.taskId);
  });

  // randomly shuffle available variants
  // but preserve which one was picked for current exam attempt id
  const randomQuestion = shuffle(
    availableVariants.length ? availableVariants : variants, // make all available if no more unused variants
    attemptId
  );

  // shuffle one more time
  // b/c using same seed for each question in same attempt produces the same order of variants for each question
  // so each question still needs unique shuffle id
  const randomVariant = shuffle(randomQuestion, randomQuestion[0].taskId);

  return randomVariant[0];
}

export function useSiteUrl(): string {
  const data = useStaticQuery<Queries.SiteDataQuery>(graphql`
    query SiteData {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  return data.site!.siteMetadata!.siteUrl!;
}

export function isAttemptExpired(startedDate: Date) {
  return differenceInDays(new Date(), startedDate) > 1;
}

type Lesson = {
  readonly slug: string;
  readonly tasks: readonly {
    readonly id: string;
  }[];
};

export function getLessonToResume(
  lessons: readonly Lesson[],
  completedTaskIds: string[]
): Lesson {
  // Find the first lesson with incomplete task(s)
  const lessonToResume = lessons.find((lesson) =>
    lesson.tasks.some((task) => !completedTaskIds.includes(task.id))
  );

  // In the case where the user has completed all the tasks in a course, resume
  // them from the last lesson
  return lessonToResume || lessons[lessons.length - 1];
}

export const useUser = () => {
  const { data, loading, error } = useGetUserQuery({
    onCompleted(data) {
      if (data.me?.__typename === "User" && data.me.memberships.length) {
        window.gtag?.("set", {
          user_role: data.me.memberships
            .map((membership) => membership.permission)
            .toString(),
        });
      }
    },
  });
  const user = data?.me?.__typename === "User" ? data.me : null;

  if (user && user.acceptedPrivacyPolicyAt === null) {
    window.location.href = `https://studio.apollographql.com/welcome?from=${encodeURIComponent(
      window.location.href
    )}`;
  }

  return {
    user,
    loading,
    error,
  };
};

export type OdysseyUser = NonNullable<ReturnType<typeof useUser>["user"]>;

export function queryTasks() {
  const tasks: Array<HTMLInputElement> = Array.from(
    document.querySelectorAll("[data-task]")
  );

  return {
    tasks,
    incompleteTasks: tasks.filter((task) => !task.checked),
    completeTasks: tasks.filter((task) => task.checked),
  };
}

export const getCurrentLesson = (
  lessons: readonly {
    slug: string;
    tasks: readonly { id: string }[];
  }[],
  userTasks: Pick<OdysseyTask, "completedAt" | "id">[]
) => {
  const courseTasks = lessons.flatMap((lesson) =>
    lesson.tasks.map((task) => task.id)
  );

  const completedTasks = userTasks
    .filter((task) => task.completedAt && courseTasks.includes(task.id))
    .map((task) => task.id);

  if (!completedTasks.length) {
    return null;
  }

  return lessons.find((lesson) => {
    const isComplete = lesson.tasks.every((task) =>
      completedTasks.includes(task.id)
    );
    return !isComplete;
  });
};

export const filterLessonsByLanguage = (
  lessons: readonly Queries.LessonFragment[],
  activeLanguage: string | undefined
) => {
  return lessons.filter((lesson) => {
    return (
      activeLanguage?.toLowerCase() ===
        lesson.frontmatter?.conditionalLanguage ||
      !lesson.frontmatter?.conditionalLanguage
    );
  });
};
